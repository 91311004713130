<template>
  <v-container class="pa-0">
    <v-card flat class="rounded-0">
      <v-card-title>
        Ejercicios
        <v-spacer></v-spacer>
        <v-btn @click="addNew" color="success" class="rounded-lg">
          <v-icon left>mdi-plus</v-icon>
          Agregar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          dense
          filled
          rounded
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          single-line
          class="rounded-lg"
          hide-details
        ></v-text-field>
      </v-card-text>

       <v-toolbar flat class="pl-2">
                     <v-checkbox hide-details=""
                            v-model="showOnlyAuthorDefined"
                            label="Ocultar ejercicios predeterminados"
                            class="rounded-lg"
                        ></v-checkbox>
                </v-toolbar>


      <v-card-text class="pa-0">
        <!-- pro filter section -->
      <!--   <v-row>
           <v-col>
            <v-select
              v-model="filters.level"
              :items="levels"
              label="Nivel"
              multiple
              hide-details
              class="rounded-lg"
            ></v-select>
           </v-col>

          <v-col>
            <v-select
              v-model="filters.force"
              :items="forces"
              label="Fuerza"
              multiple
              hide-details
              class="rounded-lg"
            ></v-select>
          </v-col>

          <v-col>
            <v-select
              v-model="filters.plane"
              :items="planes"
              label="Plano"
              multiple
              hide-details
              class="rounded-lg"
            ></v-select>

          </v-col>

          <v-col>
            <v-select
              v-model="filters.equipment"
              :items="equipment"
              label="Equipo"
              multiple
              hide-details
              class="rounded-lg"
            ></v-select>
          </v-col>

          <v-col>
            <v-select
              v-model="filters.mechanics"
              :items="mechanics"
              label="Mecánica"
              multiple
              hide-details
              class="rounded-lg"
            ></v-select>
          </v-col>

          <v-col>
            <v-select
              v-model="filters.categories"
              :items="categories"
              label="Categorías"
              multiple
              hide-details
              class="rounded-lg"
            ></v-select>

          </v-col>

          <v-col>
            <v-select
              v-model="filters.primary_muscles"
              :items="muscles"
              label="Músculos principales"
              multiple
              hide-details
              class="rounded-lg"
            ></v-select>
          </v-col>

          <v-col>
            <v-select
              v-model="filters.secondary_muscles"
              :items="muscles"
              label="Músculos secundarios"
              multiple
              hide-details
              class="rounded-lg"
            ></v-select>
          </v-col>


        </v-row> -->




        <v-data-table 
          :headers="headers"
          :items="getFilteredExercises"
          :search="search"
          :loading="loading"
          item-key="id"
         
          mobile-breakpoint="0"
          @click:row="clickList"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab x-small v-bind="attrs" v-on="on" elevation="0">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="clickList(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="deleteExercise(item.id, item.author)" v-if="!item.author">
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Eliminar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:item.info="{ item }">
            <v-btn icon v-if="item.thumbnail" color="success" x-small>
              <v-icon>mdi-image</v-icon>
            </v-btn>

            <v-btn icon v-else color="warning" x-small>
              <v-icon>mdi-image-off</v-icon>
            </v-btn>

            <v-btn icon v-if="item.videoURL" x-small>
              <v-icon>mdi-video</v-icon>
            </v-btn>

            
            <v-btn icon v-else color="warning" x-small>
              <v-icon>mdi-video-off</v-icon>
            </v-btn>
            <v-btn icon v-if=" item.url" x-small>
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
            
          </template>
        </v-data-table>
     
      
     
      </v-card-text>
    </v-card>

    <!-- Exercise Form Modal (Add/Edit) -->
     <v-dialog v-model="add" fullscreen transition="dialog-bottom-transition">
            <v-card class="rounded-0">
                <v-card-title class="px-4 py-2" v-bind:class="{ 'isCapacitorDialog': isCapacitor }">
                    <v-btn icon @click="add = false; resetVideoInput();">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>

                    <v-spacer></v-spacer>

                    <div class="headline text-center">
                        {{ exercise.id ? "Editar" : "Agregar" }} Ejercicio
                    </div>

                    <v-spacer></v-spacer>

                    <v-btn fab small color="success" @click="saveExercise" :loading="loading">
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pa-0">



                    <template v-if="imageUploadProgress !== null">
                        <p class="caption">
                            Subiendo imagen... {{ imageUploadProgress.toFixed(2) }}%
                        </p>
                        <v-progress-linear class="mb-4" :value="imageUploadProgress"
                            color="primary"></v-progress-linear>
                    </template>

                    <template v-if="videoUploadProgress !== null">
                        <p class="caption">
                            Subiendo video... {{ videoUploadProgress.toFixed(2) }}%
                        </p>
                        <v-progress-linear class="mb-4" v-if="videoUploadProgress !== null" :value="videoUploadProgress"
                            color="secondary"></v-progress-linear>

                    </template>

                  





                    <v-form ref="form" v-model="valid">
                        <v-row no-gutters>

                            <v-col cols="12" md="6">
                                <v-expansion-panels v-model="basic" multiple>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            Información básica
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field  rounded filled
                                                        class="rounded-lg" v-model="exercise.name" label="Nombre"
                                                        :rules="[rules.required]" required>

                                                        <template v-slot:prepend-inner>
                                                            <v-avatar size="60px" class="mb-4">
                                                                <img v-if="localImageURL" :src="localImageURL"
                                                                    alt="Thumbnail" @click="triggerImageUpload">
                                                                <v-btn v-else @click="triggerImageUpload" fab
                                                                    color="primary">
                                                                    <v-icon>mdi-image-plus</v-icon>
                                                                </v-btn>
                                                               <v-file-input 
  v-model="image" 
  ref="imageInput" 
  accept="image/*" 
  class="d-none" 
  hide-input 
  v-show="false" 
  @change="onImageSelected"
/>
                                                            </v-avatar>
                                                        </template>
                                                    </v-text-field>


                                                      <v-alert text dismissible type="info" prominent v-if="!exercise.author && $store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type =='admin')">
                      Ahora es posible autocompletar la información del ejercicio con inteligencia artificial a partir de su nombre <br/>


                    <v-btn class="mt-2" @click="inferAttributesFromName" :loading="loadingAI" >
                      <v-icon left>mdi-image-plus</v-icon>
                      Autocompletar con IA
                    </v-btn>
                    </v-alert>

                                                    <v-textarea prepend-inner-icon="mdi-information" rounded filled
                                                        auto-grow rows="2" class="rounded-lg"
                                                        v-model="exercise.description"
                                                        label="Descripción / Instrucciones"
                                                        required></v-textarea>



                                                    <p class="caption">Opcionales</p>
                                                    <v-card v-if="localVideoURL" class="mb-6" outlined
                                                        color="grey darken-4">
                                                        <v-card-title>
                                                            Video ilustrativo
                                                            <v-spacer></v-spacer>



                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn icon v-on="on" @click="resetVideoInput">
                                                                        <v-icon>mdi-close</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Eliminar video</span>
                                                            </v-tooltip>


                                                        </v-card-title>
                                                        <v-card-text>
                                                            <video v-if="localVideoURL" height='200px' class="mx-auto"
                                                                style="max-width:100%;display:block" controls
                                                                :src="localVideoURL"></video>
                                                        </v-card-text>

                                                    </v-card>


                                                    
                                                    <v-btn x-large rounded block v-else @click="triggerVideoUpload"
                                                        class="mb-6 rounded-lg">
                                                        <v-icon left>mdi-video-plus</v-icon>
                                                        Agregar video 
                                                    </v-btn>



                                                   <iframe v-if="exercise.url" width="100%" height="215px" :src="getURL(exercise.url)" frameborder="0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                                    <v-text-field v-else rounded filled class="rounded-lg"
                                                        v-model="exercise.url" label="YouTube URL"
                                                         prepend-inner-icon="mdi-youtube"
                                                        ></v-text-field>

                                                  <v-btn color="error" @click="exercise.url = ''" v-if="exercise.url" class="rounded-lg">
                                                        <v-icon left>mdi-close</v-icon>
                                                        Eliminar URL
                                                         <v-icon right>mdi-youtube</v-icon>
                                                    </v-btn>
                                                        



                                                    <v-file-input v-model="video" ref="videoInput" accept="video/*"
                                                        class="d-none" ></v-file-input>
                                                </v-col>

                                            </v-row>

                                        </v-expansion-panel-content>

                                    </v-expansion-panel>

                                </v-expansion-panels>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-expansion-panels v-model="advanced" multiple>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>

                                            Avanzado
                                            <span class="caption ml-2">
                                                (Opcional)
                                            </span>
                                        </v-expansion-panel-header>

                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <v-select rounded filled v-model="exercise.level" :items="levels"
                                                        hide-details label="Nivel" class="rounded-lg"></v-select>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-select rounded filled v-model="exercise.categories" hide-details
                                                        :items="categories" class="rounded-lg" label="Categorías"
                                                        multiple required></v-select>

                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-select rounded filled v-model="exercise.plane" :items="planes"
                                                        hide-details label="Plano" class="rounded-lg"></v-select>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-select rounded filled v-model="exercise.force" :items="forces"
                                                        hide-details label="Fuerza" class="rounded-lg"></v-select>
                                                </v-col>


                                                <v-col cols="12" md="6">
                                                    <v-select rounded filled v-model="exercise.mechanics" hide-details
                                                        :items="mechanics" class="rounded-lg"
                                                        label="Mecánica"></v-select>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-select rounded filled v-model="exercise.equipment" hide-details
                                                        :items="equipment" label="Equipo" class="rounded-lg"
                                                        multiple></v-select>
                                                </v-col>

                                                <v-col cols="12">
                                                    <p class="caption ma-0">Músculos trabajados</p>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-select rounded filled v-model="exercise.principal_muscles"
                                                        hide-details :items="muscles" class="rounded-lg"
                                                        label="Músculos principales" multiple></v-select>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-select rounded filled v-model="exercise.secondary_muscles"
                                                        hide-details :items="muscles" class="rounded-lg"
                                                        label="Músculos secundarios" multiple></v-select>
                                                </v-col>


                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="cropDialog" max-width="460px">
      <v-card>
        <v-card-title>
          Editar imagen
          <v-spacer></v-spacer>
          <v-btn icon @click="cropDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <vue-cropper
            v-if="selectedImage"
            ref="cropper"
            :guides="true"
            :src="selectedImage"
            :view-mode="1"
            :aspect-ratio="1"
            :background="false"
            style="width: 100%; height: 200px;"
          ></vue-cropper>
          <v-divider class="my-2"></v-divider>
          <v-alert border="left" color="primary darken-4">
            Seleccione un área de la imagen para recortarla donde se vea el ejercicio claramente.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="triggerImageUpload" v-if="selectedImage" rounded color="primary"  class="rounded-lg">
            <v-icon left>mdi-image</v-icon>
            Cambiar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" rounded v-if="selectedImage" @click="cropImage" class="rounded-lg">
            <v-icon left>mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { getFirestore, addDoc, collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { Capacitor } from '@capacitor/core';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { logAuditEvent } from '@/error/audit.js';
///        await logAuditEvent('update',this.$store.state.Auth.token.claims.user_id,`User ${this.user.id} re-enabled`)

export default {
  components: {
    VueCropper
  },
  data() {
    return {
      loadingAI: false,
         showOnlyAuthorDefined: false,
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Media', value: 'info', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      isCapacitor: Capacitor.isNativePlatform(),
      imageChanged: false,
      videoChanged: false,
      localImageURL: null,
      localVideoURL: null,
      imageUploadProgress: null,
      videoUploadProgress: null,
      loading: false,
      add: false,
      search: null,
      valid: false,
      exercises: [],
      folderExercises: [], // Holds the exercises from the selected folder
      allExercises: [], // Merged array of standard and folder exercises
      video: null,
      planes: [
        { value: 'saggital', text: 'Sagital' },
        { value: 'frontal', text: 'Frontal' },
        { value: 'transverse', text: 'Transversal' },
        { value: 'other', text: 'Otro' }
      ],
      muscles: [
                { "value": "pectoralis_major_clavicular_head", "text": "Pectoral Mayor (Cabeza Clavicular - Superior)" },
                { "value": "pectoralis_major_sternal_head", "text": "Pectoral Mayor (Cabeza Esternal - Inferior)" },
                { "value": "pectoralis_minor", "text": "Pectoral Menor" },
                
                { "value": "trapezius_upper", "text": "Trapecio Superior" },
                { "value": "trapezius_middle", "text": "Trapecio Medio" },
                { "value": "trapezius_lower", "text": "Trapecio Inferior" },
                
                { "value": "rhomboid_major", "text": "Romboides Mayor" },
                { "value": "rhomboid_minor", "text": "Romboides Menor" },
                { "value": "latissimus_dorsi", "text": "Dorsal Ancho" },
                
                { "value": "erector_spinae", "text": "Erector Espinal" },
                { "value": "multifidus", "text": "Multífido" },
                
                { "value": "deltoid_anterior", "text": "Deltoides Anterior (Frente)" },
                { "value": "deltoid_lateral", "text": "Deltoides Lateral (Lado)" },
                { "value": "deltoid_posterior", "text": "Deltoides Posterior (Trasero)" },
                { "value": "rotator_cuff", "text": "Manguito Rotador (Supraspinoso, Infraespinoso, Teres Menor, Subescapular)" },
                
                { "value": "quadriceps_rectus_femoris", "text": "Cuádriceps (Recto Femoral)" },
                { "value": "quadriceps_vastus_lateralis", "text": "Cuádriceps (Vasto Lateral)" },
                { "value": "quadriceps_vastus_medialis", "text": "Cuádriceps (Vasto Medial)" },
                { "value": "quadriceps_vastus_intermedius", "text": "Cuádriceps (Vasto Intermedio)" },
                
                { "value": "hamstrings_biceps_femoris", "text": "Isquiotibiales (Bíceps Femoral)" },
                { "value": "hamstrings_semitendinosus", "text": "Isquiotibiales (Semitendinoso)" },
                { "value": "hamstrings_semimembranosus", "text": "Isquiotibiales (Semimembranoso)" },
                
                { "value": "gluteus_maximus", "text": "Glúteo Mayor" },
                { "value": "gluteus_medius", "text": "Glúteo Medio" },
                { "value": "gluteus_minimus", "text": "Glúteo Menor" },
                
                { "value": "gastrocnemius", "text": "Gastrocnemio" },
                { "value": "soleus", "text": "Sóleo" },
                
                { "value": "biceps_brachii", "text": "Bíceps Braquial" },
                { "value": "brachialis", "text": "Braquial" },
                { "value": "brachioradialis", "text": "Braquiorradial" },
                
                { "value": "triceps_long_head", "text": "Tríceps (Cabeza Larga)" },
                { "value": "triceps_lateral_head", "text": "Tríceps (Cabeza Lateral)" },
                { "value": "triceps_medial_head", "text": "Tríceps (Cabeza Medial)" },
                
                { "value": "rectus_abdominis", "text": "Recto Abdominal" },
                { "value": "external_obliques", "text": "Oblicuos Externos" },
                { "value": "internal_obliques", "text": "Oblicuos Internos" },
                { "value": "transverse_abdominis", "text": "Transverso Abdominal" },
                
                { "value": "forearm_flexors", "text": "Flexores del Antebrazo" },
                { "value": "forearm_extensors", "text": "Extensores del Antebrazo" },
                
                { "value": "adductors", "text": "Aductores" },
                { "value": "abductors", "text": "Abductores" },
                
                { "value": "serratus_anterior", "text": "Serrato Anterior" },
                { "value": "teres_major", "text": "Redondo Mayor" },
                { "value": "teres_minor", "text": "Redondo Menor" },
                
                { "value": "iliopsoas", "text": "Iliopsoas" },
                
                { "value": "other", "text": "Otro" }

            ],
      levels: [
        { value: 1, text: 'Principiante' },
        { value: 2, text: 'Intermedio' },
        { value: 3, text: 'Avanzado' }
      ],
      forces: [
        { value: 'push', text: 'Empuje' },
        { value: 'pull', text: 'Tirón' },
        { value: 'static', text: 'Estático' },
        { value: 'dynamic', text: 'Dinámico' },
        { value: 'other', text: 'Otro' }
      ],
      exercise: {
        id: null,
        name: '',
        description: '',
        principal_muscles: [],
        secondary_muscles: [],
        categories: [],
        level: null,
        force: null,
        plane: null,
        equipment: [],
        mechanics: [],
        thumbnail: '',
        variations: [],
        videoURL: ''
      },
      variation: '',
      image: null,
      rules: {
        required: value => !!value || 'Campo obligatorio.'
      },
      basic: [0],
      advanced: [0],
        cropDialog: false,
        selectedImage: null,
        croppedImageUrl: null,
      categories: [
                { value: 'strength', text: "Fuerza" },
                { value: 'hypertrophy', text: "Hipertrofia" },
                { value: 'endurance', text: "Resistencia" },
                { value: 'power', text: "Potencia" },
                { value: 'mobility', text: "Movilidad" },
                { value: 'warmup', text: "Calentamiento" },
                { value: 'stretching', text: "Estiramiento" },
                { value: 'rehab', text: "Rehabilitación" },
                { value: 'cardio', text: "Cardio" },
                { value: 'other', text: "Otro" }
            ],
      equipment: [
                { value: 'bodyweight', text: "Peso corporal" },
                { value: 'barbell', text: "Barra" },
                { value: 'dumbbell', text: "Mancuerna" },
                { value: 'kettlebell', text: "Pesa rusa" },
                { value: 'plate', text: "Disco" },
                { value: 'bench', text: "Banco" },
                { value: 'machine', text: "Máquina" },
                { value: 'cable', text: "Cable" },
                { value: 'box', text: "Caja" },
                { value: 'bands', text: "Bandas" },
                { value: 'ball', text: "Pelota" },
                { value: 'rope', text: "Cuerda" },
                { value: 'sled', text: "Trineo" },
                { value: 'other', text: "Otro" }
            ],
      mechanics: [
                { value: 'compound', text: "Compuesto" },
                { value: 'isolation', text: "Aislamiento" }
      ],
      filters: {
        level: null,
        force: null,
        plane: null,
        equipment: [],
        mechanics: [],
        categories: [],
        primary_muscles: [],
        secondary_muscles: []
      }

    };
  },
  computed:{
    getFilteredExercises(){
      if(this.showOnlyAuthorDefined){

        this.exercises.sort((a, b) => a.name.localeCompare(b.name));


        return this.exercises.filter((e) => {
          // Check each filter criterion and return true if all filters match or no filters are applied
          return (
            // Check level filter: if it's not set or empty, pass all; otherwise, filter
            (!this.filters.level || !this.filters.level.length || this.filters.level.includes(e.level)) &&

            // Check force filter: same logic, pass all if filter is not set or empty
            (!this.filters.force || !this.filters.force.length || this.filters.force.includes(e.force)) &&

            // Check plane filter: pass all if filter is not set or empty
            (!this.filters.plane || !this.filters.plane.length || this.filters.plane.includes(e.plane)) &&

            // Check equipment filter: pass all if filter is empty, otherwise filter
            (!this.filters.equipment.length || e.equipment.some((eq) => this.filters.equipment.includes(eq))) &&

            // Check mechanics filter: pass all if empty, otherwise filter
            (!this.filters.mechanics.length || this.filters.mechanics.includes(e.mechanics)) &&

            // Check categories filter: pass all if empty, otherwise filter
            (!this.filters.categories.length || e.categories.some((cat) => this.filters.categories.includes(cat))) &&

            // Check primary muscles filter: pass all if empty, otherwise filter
            (!this.filters.primary_muscles.length || e.principal_muscles.some((muscle) => this.filters.primary_muscles.includes(muscle))) &&

            // Check secondary muscles filter: pass all if empty, otherwise filter
            (!this.filters.secondary_muscles.length || e.secondary_muscles.some((muscle) => this.filters.secondary_muscles.includes(muscle)))
          );
        });
      }else{

          let allExercises = this.exercises.concat(this.folderExercises);

        
          allExercises.sort((a, b) => a.name.localeCompare(b.name));
         return allExercises.filter((e) => {
          // Check each filter criterion and return true if all filters match or no filters are applied
          return (
            // Check level filter: if it's not set or empty, pass all; otherwise, filter
            (!this.filters.level || !this.filters.level.length || this.filters.level.includes(e.level)) &&

            // Check force filter: same logic, pass all if filter is not set or empty
            (!this.filters.force || !this.filters.force.length || this.filters.force.includes(e.force)) &&

            // Check plane filter: pass all if filter is not set or empty
            (!this.filters.plane || !this.filters.plane.length || this.filters.plane.includes(e.plane)) &&

            // Check equipment filter: pass all if filter is empty, otherwise filter
            (!this.filters.equipment.length || e.equipment.some((eq) => this.filters.equipment.includes(eq))) &&

            // Check mechanics filter: pass all if empty, otherwise filter
            (!this.filters.mechanics.length || this.filters.mechanics.includes(e.mechanics)) &&

            // Check categories filter: pass all if empty, otherwise filter
            (!this.filters.categories.length || e.categories.some((cat) => this.filters.categories.includes(cat))) &&

            // Check primary muscles filter: pass all if empty, otherwise filter
            (!this.filters.primary_muscles.length || e.principal_muscles.some((muscle) => this.filters.primary_muscles.includes(muscle))) &&

            // Check secondary muscles filter: pass all if empty, otherwise filter
            (!this.filters.secondary_muscles.length || e.secondary_muscles.some((muscle) => this.filters.secondary_muscles.includes(muscle)))
          );
        });
      }
    }
  },
  async created() {
    await this.fetchExercises();
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
  methods: {
    getURL(url) {
            return url.replace("watch?v=", "embed/");
        },
    async deleteFromStorage(url) {
      const storage = getStorage();
      const fileRef = storageRef(storage, url);
      try {
        await deleteObject(fileRef);
      } catch (error) {
        console.error('Error deleting file from storage:', error);
      }
    },
    triggerImageUpload() {
      const input = this.$refs.imageInput.$el.querySelector('input');
      if (input) input.click();
    },
    triggerVideoUpload() {
      this.$nextTick(() => {
        const input = this.$refs.videoInput.$el.querySelector('input');
        if (input) input.click();
      });
    },
    resetVideoInput() {
      this.video = null;
      this.localVideoURL = null;
      this.videoChanged = true;
    },
    resetImageInput() {
      this.image = null;
      this.localImageURL = null;
    },
    addNew() {
      this.imageUploadProgress = null;
      this.videoUploadProgress = null;
      this.resetImageInput();
      this.resetVideoInput();

      this.exercise = {
        id: null,
        name: '',
        description: '',
        thumbnail: '',
        variations: [],
        videoURL: '',
        type: 0
      };
      this.add = true;
    },
    async uploadImage(file) {
      const storage = getStorage();
      const fileExtension = 'jpg'; // Assuming jpg
      const storagePath = `thumbnails/${this.exercise.id}.${fileExtension}`;
      const imageRef = storageRef(storage, storagePath);

      const blob = await fetch(file).then((r) => r.blob());

      return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(imageRef, blob);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.imageUploadProgress = progress;
          },
          (error) => {
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            this.imageUploadProgress = null;
            resolve(url);
          }
        );
      });
    },

    async uploadVideo(file) {
      const storage = getStorage();
      const fileExtension = file.name.split('.').pop();
      const storagePath = `videos/${this.exercise.id}.${fileExtension}`;
      const videoRef = storageRef(storage, storagePath);

      return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(videoRef, file);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.videoUploadProgress = progress;
          },
          (error) => {
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            this.videoUploadProgress = null;
            resolve(url);
          }
        );
      });
    },

    // Fetch standard exercises
    async fetchExercises() {
      this.loading = true;
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, 'exercises'));
      this.exercises = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      await this.fetchFolderExercises(); // Fetch folder exercises

      logAuditEvent('read', this.$store.state.Auth.token.claims.user_id, 'User fetched exercises');
      this.loading = false;
    },

    // Fetch exercises from the selected folder
    async fetchFolderExercises() {
      const db = getFirestore();
      //const querySnapshot = await getDocs(collection(db, 'resource_folders/selectedFolderId/exercises'));
        const querySnapshot = await getDocs(collection(db, 'resource_folders'));
        let result = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        // get exercises from all folder exercises subcollections
        let folderExercises = [];

        for (const folder of result) {
          const folderQuerySnapshot = await getDocs(collection(db, `resource_folders/${folder.id}/exercises`));
          const folderExercisesData = folderQuerySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, author: folder.id }));
          folderExercises = folderExercises.concat(folderExercisesData);
        }

        logAuditEvent('read', this.$store.state.Auth.token.claims.user_id, 'User fetched folder exercises');
        this.folderExercises = folderExercises;
    },

    async saveExercise() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const db = getFirestore();
        const exerciseData = { ...this.exercise };
        delete exerciseData.id; // Remove ID for new exercises

        let docRef;
        
        if(this.exercise.author){

            docRef  = doc(db, `resource_folders/${this.exercise.author}/exercises`, this.exercise.id)
            await updateDoc(docRef, exerciseData);
            
            logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `User updated folder exercise ${this.exercise.name} in folder ${this.exercise.author} data to ${JSON.stringify(exerciseData)}`);

        }else{

            if (this.exercise.id) {
                docRef = doc(db, 'exercises', this.exercise.id);
                await updateDoc(docRef, exerciseData);

                logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `User updated exercise ${this.exercise.name} data to ${JSON.stringify(exerciseData)}`);
            } else {
                docRef = await addDoc(collection(db, 'exercises'), exerciseData);
                this.exercise.id = docRef.id;

                logAuditEvent('create', this.$store.state.Auth.token.claims.user_id, `User created exercise ${this.exercise.name} with data ${JSON.stringify(exerciseData)}`);
            }
        }

        if (this.imageChanged && this.croppedImageUrl) {
                this.exercise.thumbnail = await this.uploadImage(this.croppedImageUrl);

                await updateDoc(docRef, { thumbnail: this.exercise.thumbnail});

                logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `User updated exercise ${this.exercise.name} thumbnail to ${this.exercise.thumbnail}`);
                this.imageChanged = false;
            }
        


       

        this.loading = false;
        this.$notify({
          group: 'feedback',
          title: 'Ejercicio guardado',
          text: 'El ejercicio se ha guardado correctamente.',
          type: 'success'
        });
        await this.fetchExercises();
        this.add = false;
      }
    },

    async deleteExercise(id, folder = false) {
      const db = getFirestore();
      let collectionPath = 'exercises';
      if (folder) {
        collectionPath = `resource_folders/${folder}/exercises`;
      }

      const exerciseRef = doc(db, collectionPath, id);
      let exercise = folder? this.folderExercises.find((e) => e.id === id) : this.exercises.find((e) => e.id === id);


      if (exercise.thumbnail) {
        await this.deleteFromStorage(exercise.thumbnail);
        logAuditEvent('delete', this.$store.state.Auth.token.claims.user_id, `User deleted exercise ${exercise.name} thumbnail`);
      }
      if (exercise.videoURL) {
        await this.deleteFromStorage(exercise.videoURL);
        logAuditEvent('delete', this.$store.state.Auth.token.claims.user_id, `User deleted exercise ${exercise.name} video`);
      }
      await deleteDoc(exerciseRef);
      logAuditEvent('delete', this.$store.state.Auth.token.claims.user_id, `User deleted exercise ${exercise.name} data ${ JSON.stringify(exercise) }`);
      await this.fetchExercises();
    },

    clickList(item) {
      this.exercise = { ...item };
      if (this.exercise.videoURL) {
        this.localVideoURL = this.exercise.videoURL;
      } else {
        this.localVideoURL = null;
      }
      if (this.exercise.thumbnail) {
        this.localImageURL = this.exercise.thumbnail;
      } else {
        this.localImageURL = null;
      }
      this.add = true;
    },

    onImageSelected(file) {
      if (!file) return;
      this.selectedImage = null;

      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedImage = e.target.result;
        this.cropDialog = true;
      };
      reader.readAsDataURL(file);
    },

    cropImage() {
      this.croppedImageUrl = this.$refs.cropper.getCroppedCanvas({ width: 400, height: 400 }).toDataURL();
      this.localImageURL = this.croppedImageUrl;
      this.imageChanged = true;
      this.cropDialog = false;
    },

    async inferAttributesFromName(){
      //validate the exercise form
      if (this.$refs.form.validate()) {

        const name = this.exercise.name.toLowerCase();

        const functions = getFunctions();
        const generateExerciseJSON = httpsCallable(functions, 'generateExerciseJSON');

        try {
          this.loadingAI = true;
          const result = await generateExerciseJSON({ exerciseName: name });
          this.loadingAI = false;

          if (result.data) {
            logAuditEvent('ai', this.$store.state.Auth.token.claims.user_id, `User inferred attributes from exercise name '${name}' to ${JSON.stringify(result.data)}`);
            this.exercise = { ...this.exercise, ...result.data };

            this.$notify({
              group: 'feedback',
              title: 'Información inferida',
              text: '',
              type: 'success'
            });
          } else {
            logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `User failed to infer attributes from exercise name ${name}`);
            this.$notify({
              group: 'feedback',
              title: 'Error',
              text: 'No se pudo inferir información del nombre del ejercicio.',
              type: 'error'
            });
          }
        } catch (error) {
          this.loadingAI = false;
          logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `User failed to infer attributes from exercise name ${name}, error ${error} message: ${error.message}`);
          this.$notify({
            group: 'feedback',
            title: 'Error',
            text: 'No se pudo inferir información del nombre del ejercicio.',
            type: 'error'
          });  
        }           
      }



    }
  }
};
</script>

<style scoped>
.isCapacitorDialog {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}
</style>
